import React from 'react';

const Footer = () => {
  return (
    <div className="app-footer">
      Designed and Developed by&nbsp;
      <a href="https://www.ekathvainnovations.com" rel="noreferrer" target="_blank">
         Ekathva Innovations
      </a>
    </div>
  );
};

export default Footer;
