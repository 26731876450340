import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { AppIcons } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { MasterService, UserService } from '../../services';
import { IDash, IDashSummary } from '../../core/models';
import { emptyDash } from '../../core/constants';
const DashboardPage = () => {
  const [dashdata, setDashData] = useState<IDash>(emptyDash);
  const [summary, setSummary] = useState<IDashSummary>({
    totalProfiles: 0,
    newProfiles: 0,
    maleProfiles: 0,
    femaleProfiles: 0,
    rejectedProfiles: 0,
    disabledProfiles: 0,
    hinduJangamaMaleProfiles: 0,
    hinduJangamaFemaleProfiles: 0,
    hinduLingayathaMaleProfiles: 0,
    hinduLingayathaFemaleProfiles: 0,
    hinduSaduLingayathMaleProfiles: 0,
    hinduSaduLingayathFemaleProfiles: 0,
    secondMarriageMaleProfiles: 0,
    secondMarriageFemaleProfiles: 0,
  });
  const navigate = useNavigate();
  const getDashData = async () => {
    try {
      const res = await MasterService.dashboardData();
      if (res.status === 200) {
        setDashData(res.data);
        setSummary(res.data);
      }
    } catch (error) {
      alert(error.message);
    }
  };
  useEffect(() => {
    getDashData();
  }, []);
  return (
    <Container className="my-5">
      <h1>Dashboard</h1>
      <Row>
        <Col lg={3}>
          <div className="dashboard-card" onClick={() => navigate('all-profiles')}>
            <div className="main">
              <span>
                <h2>{dashdata.totalProfiles}</h2>
                <p>All Profiles</p>
              </span>
              <img src={AppIcons.allProfiles} alt="profiles" />
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="dashboard-card" onClick={() => navigate('new-profiles')}>
            <div className="main">
              <span>
                <h2>{dashdata.newProfiles}</h2>
                <p>New Profiles</p>
              </span>
              <img src={AppIcons.profile} alt="profiles" />
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="dashboard-card" onClick={() => navigate('disabled-profiles')}>
            <div className="main">
              <span>
                <h2>{dashdata.disabledProfiles}</h2>
                <p>Disabled</p>
              </span>
              <img src={AppIcons.profile} alt="profiles" className="disabled-img" />
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="dashboard-card" onClick={() => navigate('add-profile')}>
            <div className="main">
              <span>
                <h2>Add</h2>
                <p>Profile</p>
              </span>
              <img src={AppIcons.newProfile} alt="profiles" />
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="dashboard-card" onClick={() => navigate('rejected-profiles')}>
            <div className="main">
              <span>
                <h2>{dashdata.rejectedProfiles}</h2>
                <p>Rejected</p>
              </span>
              <img src={AppIcons.newProfile} alt="profiles" className="disabled-img" />
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="dashboard-card">
            <div className="main">
              <span>
                <h2>{dashdata.femaleProfiles}</h2>
                <p>Brides</p>
              </span>
              <img src={AppIcons.bride} alt="profiles" />
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="dashboard-card">
            <div className="main">
              <span>
                <h2>{dashdata.maleProfiles}</h2>
                <p>Grooms</p>
              </span>
              <img src={AppIcons.groom} alt="profiles" />
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="dashboard-card" onClick={() => navigate('testimonials')}>
            <div className="main">
              <span>
                <h2></h2>
                <p>Testimonials</p>
              </span>
              <img src={AppIcons.testimonial} alt="profiles" />
            </div>
          </div>
        </Col>
        <Col lg={3}>
          <div className="dashboard-card" onClick={() => navigate('gallery')}>
            <div className="main">
              <span>
                <h2></h2>
                <p>Gallery</p>
              </span>
              <img src={AppIcons.gallery} alt="profiles" />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="my-3">
        <h2>Profiles Summary</h2>
        <Col lg={6}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Caste</th>
                <th>Male</th>
                <th>Female</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hindu Jangama </td>
                <td>{summary.hinduJangamaMaleProfiles}</td>
                <td>{summary.hinduJangamaFemaleProfiles}</td>
              </tr>
              <tr>
                <td>Hindu Lingayatha</td>
                <td>{summary.hinduLingayathaMaleProfiles}</td>
                <td>{summary.hinduLingayathaFemaleProfiles}</td>
              </tr>
              <tr>
                <td>Hindu Sadhu Lingayatha</td>
                <td>{summary.hinduSaduLingayathMaleProfiles}</td>
                <td>{summary.hinduSaduLingayathFemaleProfiles}</td>
              </tr>
              <tr>
                <td>Second Marriage</td>
                <td>{summary.secondMarriageMaleProfiles}</td>
                <td>{summary.secondMarriageFemaleProfiles}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
export default DashboardPage;
